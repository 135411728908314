import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import Spinner from '../../components/spinner/spinner';
import { Button } from 'react-bootstrap';
import SocialButtons from '../../components/socialButtons/socialButtons';
import { Helmet } from "react-helmet";
import RequestHelper from '../../helpers/request';
export default class BlogDetail extends React.Component {
    constructor(props) {
        super(props);
        const blogId = props.match.params.id || null;
        this.state = {
            blog: '',
            blogId,
            isLoading: false,
            id: null,
            isError: !blogId,
            tags: []
        };
    }

    async componentDidMount() {
        this.getBlog(this.props.match.params.id);
        const retval = await RequestHelper.getTags();
        if (retval.status === 200) {
            this.filterTags(retval.data);
        } else {
            this.setState({ isError: true })
        }
    }

    filterTags = (tags) => {
        if (tags && tags.length) {
            const filteredTags = tags.filter(tag => tag.field_blog_active === "true");
            this.setState({ tags: filteredTags })
        }
    }

    getBlog = async (blog) => {
        this.setState({ isLoading: true });
        const retval = await RequestHelper.getBlogDetail({ blog });
        if (retval && retval.status === 200) {
            if (retval.data && retval.data.rows && retval.data.rows.length && retval.data.rows[0]) {
                this.setState({ blog: retval.data.rows[0], isLoading: false });
            } else {
                this.setState({ isError: true, isLoading: false });
            }
        } else {
            this.setState({ isError: true, isLoading: false });
        }
    }

    changeBlog = (id) => {
        window.dataLayer.push({
            'event': 'Blog_related_read_click',
            'eventCategory': 'Blog_related_read_click',
            'eventAction': 'Min_Read_click',
            'eventLabel': id, // {{dynamic}} contains the blog title i.e 7_rules_to_follow_when_taking_a_personal_loan
        });
        this.getBlog(id);
        window.scrollTo(0, 0);
    }

    renderRelated = () => {
        let count = 0;
        const { blog } = this.state;
        if (!(blog.related_blog && blog.related_blog.length)) return null;
        return blog.related_blog.map((blog) => {
            if (count > 2) return null;
            const { slug, title, icon_image, reading_time, tags } = blog;
            return (<div className="related-reading col-12 col-md-6 col-lg-6 col-xl-4 col-sm-12 mb-4" key={title}>
                <Link to={`/blog/${slug}`} onClick={() => {
                    this.changeBlog(slug);
                }} >
                    <div className="card pointer h-100">
                        <img src={icon_image} className="card-img-top" alt="..." />
                        <div className="card-body ">
                            <h4>{title}</h4>
                        </div>
                        <div className="blogfooter">
                            <div className="row">
                                <div className="col-6 less-opacity float-left">{(tags[0] && tags[0].name) || ''}</div>
                                <div className="col-6 text-right txt-red">
                                    {reading_time} min read
                                    </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>);
        })
    }

    pushToDatalayer = (blogId, url) => {
        window.dataLayer.push({
            'event': 'blog_get_started_click',
            'eventCategory': 'blog_get_started_click',
            'eventAction': blogId, // {{dynamic}} contains blog name i.e 7_rules_to_follow_when_taking_a_personal_loan
            'eventLabel': url, // {{dynamic}} contains the target url i.e  https://www.bajajcapitalone.com/loans/personal-loan/
        });
    }

    breadCrumbsDataLayer = (url, type) => {
        window.dataLayer.push({
            'event': 'blog_breadcrumbs_click',
            "eventCategory": "blog_breadcrumbs_click", // {{dynamic}} contains breadcrumb name i.e Personal Loan",
            'eventAction': type,
            'eventLabel': `${window.location.host}${url}`, // {{dynamic}} contains the blog url 
        });
    }


    tagsDataLayer = (category) => {
        window.dataLayer.push({
            'event': 'blog_tags_click',
            "eventCategory": "blog_tags_click", // {{dynamic}} contains tag name i.e Personal Loan"
            'eventAction': category,
            'eventLabel': `${window.location.host}/blogs`, // {{dynamic}} contains the blog url
        });
    }

    renderTags = () => {
        const { tags } = this.state;
        if (tags.length) {
            return tags.map(tag => {
                const key = tag.name.replace(/\s+/g, '-')
                return (
                    <React.Fragment key={tag.name}>
                        <Button variant="link" onClick={() => {
                            this.tagsDataLayer(tag.name);
                            this.props.history.push(`/blogs?category=${key}`);
                        }
                        }
                            className="btn btn-light btn-sm mb-1" >{tag.name}</Button>
                        &nbsp;&nbsp;
                </React.Fragment>
                )
            })
        }
    }

    createMarkup = (data) => { return { __html: data }; };

    render() {
        const { blog, isLoading, isError } = this.state;
        if (isError) {
            return (
                <Redirect to={{
                    pathname: "/404",
                    state: {
                        firstText: "Blog Not Found.",
                        secondText: "View all Blogs",
                        buttonText: "Blogs",
                        route: "/blogs"
                    }
                }} />
            )
        }

        if (isLoading || !blog) {
            return (
                <Spinner style={{ position: 'fixed', marginTop: '50vh' }} />)
        }

        const category = blog.tags[0].name.replace(/\s+/g, '-');
        return (
            <main role="main">
                <Helmet>
                    <title>{blog.seo ? blog.seo.title : blog.title}</title>
                    <meta name="description" content={blog.seo ? blog.seo.description : blog.blog_description} />
                    <meta name="keywords" content={blog.seo ? blog.seo.keywords : 'bajaj capital'} />
                </Helmet>
                {/*Banner */}
                <section className="aboutbanner blog-header blog-detail">
                    <div className="blog-banner">
                        <div className="container pt80 pb40">
                            <div className="row align-items-center">
                                <div className="col-12 col-md-4">
                                    <div className="mobileShow ver-show">
                                        <div className="row">
                                            <div className="col-12 text-right ">
                                                <nav aria-label="breadcrumb" className="blog-breadcumb ">
                                                    <ol className="breadcrumb">
                                                        <li className="breadcrumb-item"><Link onClick={() => this.breadCrumbsDataLayer('/', 'Home')}
                                                            to="/">Home</Link></li>
                                                        <li className="breadcrumb-item"><Link onClick={() => this.breadCrumbsDataLayer('/blogs', 'Blogs')}
                                                            to="/blogs">Blogs</Link></li>
                                                        <li className="breadcrumb-item"><Link onClick={() => this.breadCrumbsDataLayer(`/blogs?category=${category}`, blog.tags[0].name)}
                                                            to={`/blogs?category=${category}`}
                                                        >{blog.tags[0].name}</Link></li>
                                                    </ol>
                                                </nav>
                                            </div>
                                            <div className="col-12 col-lg-9 border-left">
                                            </div>
                                        </div>
                                    </div>
                                    <img src={blog.blog_image} className="img-fluid md-done ver-none" alt="blog" /> </div>
                                <div className="col-12 col-md-8 sec-heading">
                                    <span className="md-done ver-none"> {blog.reading_time} min reading</span>
                                    <h1> {blog.title}</h1>
                                    <p>{blog.blog_description}</p>
                                    <img src={blog.blog_image} className="img-fluid mobileShow ver-show" alt="blog" />
                                    <span className="mobileShow ver-show"> {blog.reading_time} min reading</span>
                                    <SocialButtons text={blog.title} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container md-done ver-none">
                        <div className="row">
                            <div className="col-12 text-right ">
                                <nav aria-label="breadcrumb" className="blog-breadcumb ">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link onClick={() => this.breadCrumbsDataLayer('/', 'Home')}
                                            to="/">Home</Link></li>
                                        <li className="breadcrumb-item"><Link onClick={() => this.breadCrumbsDataLayer('/blogs', 'Blogs')}
                                            to="/blogs">Blogs</Link></li>
                                        <li className="breadcrumb-item"><Link onClick={() => this.breadCrumbsDataLayer(`/blogs?category=${category}`, category)}
                                            to={`/blogs?category=${category}`}
                                        >{blog.tags[0].name}</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">{blog.title}</li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-12 col-lg-9 border-left">
                            </div>
                        </div>
                    </div>
                </section>
                {/* Bajaj Capital Blog Details */}
                <section className="blog-details">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-9 order-0 order-lg-1 border-left">
                                <article className="blog text-justify ">
                                    <div className=" sec-heading" dangerouslySetInnerHTML={this.createMarkup(blog.body)} />
                                </article>
                            </div>
                            <div className="col-12 col-lg-3 blog-side ">
                                <div className="card-body">
                                    <h4 className="card-title">Tags</h4>
                                    {this.renderTags()}
                                </div>
                                <div className="card  shadow-sm getstarted">
                                    <div className="card-body ">
                                        <p> {blog.get_started_description}</p>
                                        <a onClick={() => {
                                            this.pushToDatalayer(blog.id, blog.tags[0].product_url)
                                        }} href={blog.tags[0].product_url} target={(blog.tags[0].new_tab === 'true') ? 'blank' : undefined} className="btn btn-primary btn-block">Get Started Now
    </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Bajaj Capital Blog Details End */}
                {/* Bajaj Capital Blog Related  */}
                <section className="homeblog pt40 pb40 relatedblog">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 sec-heading">
                                <h2> Related Reading</h2>
                            </div>
                            {this.renderRelated()}
                        </div>
                    </div>
                </section>
                {/* Bajaj Capital Blog Related End */}
            </main>
        )
    }
}