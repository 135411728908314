import React, { useState, useRef } from 'react';
import { Button, Overlay, Tooltip } from 'react-bootstrap';

const copyLink = (setShow) => {
    const dummy = document.createElement('input'),
        text = window.location.href;
    dummy.style = "position: absolute; left: -1000px; top: -1000px";
    document.body.appendChild(dummy);
    dummy.value = text;
    if (navigator.userAgent.match(/ipad|iphone/i)) {
        const range = document.createRange();
        range.selectNode(dummy);
        window.getSelection().addRange(range);
    } else {
        dummy.select();
    }
    document.execCommand('copy');
    document.body.removeChild(dummy);
    setShow(true);
    setTimeout(() => {
        setShow(false)
    }, 2000)
};

const pushToDataLayer = (eventCategory, eventLabel) => {
    window.dataLayer.push({
        'event': 'social_blog_share',
        'eventAction': 'social_blog_share_click',
        eventCategory,
        eventLabel
    });
}

export default ({ text = 'One Bajaj' }) => {
    const url = encodeURIComponent(`${window.location.href}`);
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&text=${text}`;
    const twitterUrl = `http://twitter.com/share?text=${text}&url=${url}&original_referer=${url}`;
    const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite?url=${url}`;
    return (
        <div className="icon-bar mt-5">
            <a onClick={() => pushToDataLayer('facebook', fbUrl)} href={fbUrl} target="_blank" rel="noopener noreferrer" className="facebook"><i className="fab fa-facebook-f" /></a>
            <a onClick={() => pushToDataLayer('twitter', twitterUrl)} href={twitterUrl} target="_blank" rel="noopener noreferrer" className="twitter"><i className="fab fa-twitter" /></a>
            <a onClick={() => pushToDataLayer('linkedin', linkedinUrl)} href={linkedinUrl} target="_blank" rel="noopener noreferrer" className="linkedin"><i className="fab fa-linkedin-in" /></a>
            <Button ref={target} variant="link" onClick={() => {
                copyLink(setShow);
                pushToDataLayer('copy', window.location.href);
            }} className="share sharehover"><i className="fas fa-share-alt" /></Button>
            <Overlay target={target.current} show={show} placement="auto">
                {props => (
                    <Tooltip id="overlay-example" {...props}>
                        Link copied!
          </Tooltip>
                )}
            </Overlay>
        </div>
    )
}

