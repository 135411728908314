const apiConstants = {
    dev: {
        getBranch: 'https://portfolio.wealthmaker.in/wmapi/WealthMakerAPIKit.svc/GetBranch',
        cmsUrl: 'https://dev-cms.bajajcapitalone.com/services/api/front-page?_format=json',
        blogs: 'https://dev-cms.bajajcapitalone.com/services/api/blog?_format=json',
        products: 'https://dev-cms.bajajcapitalone.com/services/api/products?_format=json',
    },
    stage: {
        getBranch: 'https://portfolio.wealthmaker.in/wmapi_live/WealthMakerAPIKit.svc/GetBranch',
        cmsUrl: 'https://stage-cms.bajajcapitalone.com/services/api/front-page?_format=json',
        blogs: 'https://stage-cms.bajajcapitalone.com/services/api/blog?_format=json',
        products: 'https://stage-cms.bajajcapitalone.com/services/api/products?_format=json',
    },
    prod: {
        getBranch: 'https://portfolio.wealthmaker.in/wmapi_live/WealthMakerAPIKit.svc/GetBranch',
        cmsUrl: 'https://cms.bajajcapitalone.com/services/api/front-page?_format=json',
        blogs: 'https://cms.bajajcapitalone.com/services/api/blog?_format=json',
        products: 'https://cms.bajajcapitalone.com/services/api/products?_format=json',
    }
};

export default apiConstants;