import React, { Suspense, lazy } from 'react';
import { Redirect } from 'react-router-dom';
import Products from './components/products/products';
import SubProducts from './components/subProducts/subProducts';
import Spinner from '../../components/spinner/spinner';
import RequestHelper from '../../helpers/request';
const Solutions = lazy(() => import('./components/solutions/solutions'));
const WhyNumber = lazy(() => import('./components/whyNumber/whyNumber'));
const Blogs = lazy(() => import('./components/blogs/blogs'));
const GetInTouch = lazy(() => import('./components/getInTouch/getInTouch'));
export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.timer = false;
        sessionStorage.setItem('onebajaj', 'capital');
        setTimeout(() => {
            sessionStorage.removeItem('onebajaj');
        }, 500)
        this.state = {
            renderElements: [],
            rendered: 0,
            isScroll: false,
            data: '',
            isError: false,
            isLoading: true,
        }
    }
    async componentDidMount() {
        const retval = await RequestHelper.getDashboardData();
        if (retval && retval.status === 200) {
            if (retval.data && retval.data.rows && retval.data.rows.length) {
                this.setState({ data: retval.data.rows[0], isLoading: false });
                window.addEventListener('scroll', this.handleScroll);
                window.scrollTo(0, 0);
                this.timer = setTimeout(() => {
                    if (!this.state.isScroll) {
                        this.setState({
                            isScroll: true
                        });
                    }
                }, 4000)
            } else {
                this.setState({ isError: true, isLoading: false });
            }
        } else {
            this.setState({ isError: true, isLoading: false });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
        clearTimeout(this.timer);
    }

    handleScroll = () => {
        this.setState({
            isScroll: true
        });
        window.removeEventListener('scroll', this.handleScroll, false);
    }

    renderLazyComponents = () => {
        if (this.state.isScroll) {
            const { data } = this.state;
            return (
                <Suspense fallback={<Spinner style={{ position: 'fixed' }} />}>
                    <WhyNumber key="WhyNumber" 
                        assetsCount={data.asset_under_advisor}
                        experienceCount={data.experience}
                        investorsCount={data.investor} 
                    />
                    <Solutions key="Solutions" categories={data.category_list} switchTime={data.category_switch_time} />
                    <Blogs key="Blogs" blogs={data.blog_data} />
                    <GetInTouch key="GetInTouch" description={data.get_in_touch} />
                </Suspense>
            )
        }
    }

    render() {
        const { isLoading, isError, data } = this.state;
        if (isLoading) {
            return (
                <Spinner style={{ position: 'fixed', marginTop: '50vh' }} />
            )
        }
        if (isError) {
            return (
                <Redirect to={{
                    pathname: "/404",
                    state: {
                        firstText: "Page could not be loaded",
                        secondText: "Please try again.",
                        buttonText: "RELOAD",
                    }
                }} />
            )
        }
        return (
            <main role="main">
                <Products
                    description={data.banner_description}
                    images={data.banner_image}
                    text={data.banner_text}
                    switchTime={data.banner_switch_time}
                />
                <SubProducts
                    productList={data.product_list}
                />
                {this.renderLazyComponents()}
            </main>
        )
    }
}