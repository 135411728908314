import React from 'react';
import { Card } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
class Blogs extends React.Component {
    bottomOffset = (window.innerWidth < 475) ? 550 : 300;
    timer = null;
    isLoaded = false;

    componentDidMount() {
        this.timer = setTimeout(() => {
            window.addEventListener('scroll', this.handleScroll);
        }, 1000)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
        clearTimeout(this.timer);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.category !== this.props.category) {
            this.isLoaded = false;
            window.addEventListener('scroll', this.handleScroll);
        }
    }

    handleScroll = () => {
        const { body } = document;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight);
        if ((window.pageYOffset >= docHeight - window.innerHeight - this.bottomOffset)) {
            if (this.props.pager.current_page < (this.props.pager.total_pages - 1)) {
                this.loadMore();
            } else {
                window.removeEventListener('scroll', this.handleScroll, false);
            }

        }
    }

    createMarkup = (html) => {
        return { __html: html };
    }

    pushToDataLayer = (id, category) => {
        window.dataLayer.push({
            'event': 'blog_min_read_click',
            'eventCategory': 'blog_min_read_click',
            'eventAction': id, // {{dynamic}} contains blog name i.e 7_rules_to_follow_when_taking_a_personal_loan
            'eventLabel': category, // {{dynamic}} contains the blog title i.e Personal_Loan, Mutual_fund
        });
    }

    renderBlogs = () => {
        const { blogs } = this.props;

        if (!(blogs && blogs.length)) {
            return (<h1 className="col-lg-12 text-center">No results found.</h1>)
        } else {
            return blogs.map((blog, index) => {
                const { slug, title, blog_image, reading_time, tags, blog_description } = blog;
                return (
                    <div className="col-12 col-md-6 col-lg-6 col-xl-4 col-sm-12 mb-5" key={`index${index}`}>
                        <Link to={`/blog/${slug}`} onClick={() => {
                            this.pushToDataLayer(slug, blog.category);
                        }} >
                            <Card className="pointer h-100" >
                                <Card.Img variant="top" src={blog_image} />
                                <Card.Body>
                                    <Card.Title>{title}</Card.Title>
                                    <Card.Text>
                                        {blog_description.substr(0, 75)}...
                                </Card.Text>
                                </Card.Body>
                                <div className="blogfooter">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-xs-6 col-6 col-lg-6 less-opacity float-left">{tags[0].name}</div>
                                        <div className="col-md-6 col-sm-6 col-xs-6 col-6 col-lg-6 text-right txt-red">
                                            {reading_time} min read
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Link>
                    </div>
                )
            })
        }
    }

    loadMore = () => {
        if (!this.isLoaded) {
            this.isLoaded = true;
            this.props.getBlogs({ isLoadMore: true, page: this.props.pager.current_page + 1 });
            this.timer = setTimeout(() => {
                this.isLoaded = false;
                window.addEventListener('scroll', this.handleScroll);
                this.setState({ isLoading: false })
            }, 1000)
        }
    }

    render() {
        const { blogs, search } = this.props;
        const blogsLength = blogs.length;
        return (
            <section className="homeblog pb80">
                <div className="container">
                    {(search && (search.length > 2) && blogsLength) ? (<div className="text-center">
                        <h4>
                            {blogsLength} {(blogsLength === 1) ? 'result' : 'results'} found.
                        </h4>
                        <br />
                    </div>) : null}
                    <div className="row">
                        {this.renderBlogs()}
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(Blogs);