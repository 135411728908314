import React from 'react';
// import Slider from "react-slick";
export default ({ productList }) => {
    const renderSubProducts = () => {
        if (!(productList && productList.length)) return null;
        productList = productList.sort((a, b) => (a.sequence - b.sequence));
        return productList.map((item) => {
            const { product_des, product_icon, product_name, product_url, new_tab } = item;
            // if (!(product_des && product_icon && product_name && product_url)) return null;

            return (<div className="col-lg-3 col-md-3 col-sm-6 col-6 sub-products mb-3" key={product_name}>
                <a href={product_url} target={new_tab && '__blank'} variant="link"
                    onClick={() => pushToDataLayer({
                        url: product_url,
                        menu: product_name
                    })}>
                    <div className="card h-100"> <img src={product_icon} className="card-img-top" alt="..." />
                        <h5 className="card-title">{product_name}</h5>
                        <div className="card-body md-done">
                            <div className="md-done">
                                <p>{product_des} </p>
                            </div>
                            <p><span className="md-done">Know more</span> <i className="styled-link__icon fas fa-arrow-right m-arrow" /> </p>
                        </div>
                    </div> </a>
            </div>)
        })
    }

    const pushToDataLayer = ({ url, menu }) => {
        window.dataLayer.push({
            'event': 'Product_logo_click',
            'eventCategory': `${menu}_Click`,
            'eventAction': window.location.href,
            'eventLabel': url,
        });
    }

    return (
        <section className={`bcl-credit-report ${window.innerWidth > 800 ? 'pt120' : 'pt80'}  pb40`}>
            <div className="container">
                <div className="row sub-products">
                    {renderSubProducts()}
                </div>
            </div>
        </section>
    );
}