import React from 'react';
import SearchBanner from './components/searchBanner';
import BlogsList from './components/blogsList';
import RequestHelper from '../../helpers/request';
import { Helmet } from "react-helmet";

export default class Blogs extends React.Component {
    constructor(props) {
        super(props);
        let category = '';
        if (props.location.search) {
            const url_string = window.location.href;
            const url = new URL(url_string);
            category = url.searchParams.get("category");
        }
        category = props.history.location.category || category || '';
        category = category.replace(/-/g, ' ');
        this.state = {
            blogs: [],
            search: '',
            category,
            tags: [],
            pager: {}
        }
    }

    onSearch = (event) => {
        this.setState({ search: event.target.value }, () => {
            if(this.state.search.length > 2 || !this.state.search){
                this.getBlogs({});
            }
        })
    }

    async componentDidMount() {
        this.getBlogs({});
        const retval = await RequestHelper.getTags();
        if (retval.status === 200) {
            this.filterTags(retval.data);
        } else {
            this.setState({ isError: true })
        }
    }

    filterTags = (tags) => {
        if (tags && tags.length) {
            const filteredTags = tags.filter(tag => tag.field_blog_active === "true");
            this.setState({ tags: filteredTags })
        }
    }

    getBlogs = async ({ isLoadMore = false, page = 0 }) => {
        this.setState({ isLoading: true });
        const { search } = this.state;
        let { category } = this.state;
        if (category) {
            category = category.replace(/-/g, ' ');
        }
        const retval = await RequestHelper.getBlogs({ category, search, page });
        if (retval && retval.status === 200) {
            if (retval.data && retval.data.rows && retval.data.rows.length) {
                if (isLoadMore) {
                    this.setState({
                        blogs: [...this.state.blogs, ...retval.data.rows], 
                        isLoading: false,
                        pager: retval.data.pager
                    });
                } else {
                    this.setState({
                        blogs: retval.data.rows, isLoading: false,
                        pager: retval.data.pager
                    });
                }
            } else {
                this.setState({ isError: true, isLoading: false, blogs: [] });
            }
        } else {
            this.setState({ isError: true, isLoading: false, blogs: [] });
        }
    }

    pushToDataLayer = (count) => {
        window.dataLayer.push({
            'event': 'blog_Search_bar_click',
            'eventAction': 'blog_Search_bar_click',
            'eventCategory': this.state.search, // {{dynamic}} contains the text in search bar
            'eventLabel': count,
        });
    }

    onCategorySelect = (category) => {
        if (category !== this.state.category) {
            let url = '/blogs';
            if (category) {
                const key = category.replace(/\s+/g, '-')
                url = url + `?category=${key}`
            }
            this.props.history.push(url);
            window.dataLayer.push({
                'event': 'Blog_category_click',
                'eventCategory': category,
                'eventAction': 'Blog_category_click'
            });
            this.setState({ category }, () => {
                this.getBlogs({});
            })
        }
    }

    render() {
        const { search, blogs, category, tags, pager } = this.state;
        return (
            <React.Fragment>
                 <Helmet>
                    <meta name="robots" content="noindex"/> 
                    </Helmet>
                <main role="main">
               
                    {/*Banner */}
                    <SearchBanner
                        tags={tags}
                        search={search}
                        onSearch={this.onSearch}
                        onCategorySelect={this.onCategorySelect}
                        category={category}
                    />
                    {/* Bajaj Capital Blog List */}
                    <BlogsList
                        search={search}
                        blogs={blogs}
                        pager={pager}
                        getBlogs={this.getBlogs}
                        category={category}
                    />
                </main>

            </React.Fragment>
        )
    }
}
