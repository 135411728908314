import axios from 'axios';
import apiConstants from '../constants/api';
const env = process.env.REACT_APP_ENV || 'dev';
const { cmsUrl, getBranch, blogs, products } = apiConstants[env];

const requestHelper = {
    getBranches: async ({ type }) => {
        try {
            const branches = await axios({
                method: 'post',
                url: getBranch,
                data: { BusinessType: type },
                config: {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    }
                }
            });
            return branches;
        } catch (err) {
            return {
                message: err.message,
                error: true
            }
        }
    },
    getDashboardData: async () => {
        try {
            return await axios.get(cmsUrl);
        } catch (err) {
            return {
                message: err.message,
                error: true
            }
        }
    },
    getBlogDetail: async ({ blog }) => {
        try {
            return await axios.get(`${blogs}&slug_value=${blog}`);
        } catch (err) {
            return {
                message: err.message,
                error: true
            }
        }
    },
    getBlogs: async ({ category = '', search = '', page = 0 }) => {
        try {
            let url = `${blogs}&page=${page}`;
            if (search) {
                url = url + `&keywords=${search}`
            }
            if (category) {
                url = url + `&field_product_tags_target_id=${category}`
            }
            return await axios.get(url);
        } catch (err) {
            return {
                message: err.message,
                error: true
            }
        }
    },
    getTags: async () => {
        try {
            return await axios.get(products);
        } catch (err) {
            return {
                message: err.message,
                error: true
            }
        }
    },
}

export default requestHelper;