const policies = [
    {
        title: 'Coverage under this Policy/Protection to:',
        body: [
            `All Visitors ("Visitors") who visit this website ('Site') and provide information to Investors India Technologies Private Limited ('IITPL') online through this Site are covered under this Policy.`,
            `Information covered by this Policy: This Policy seeks to cover private information of the Visitors provided to the Site as also any information collected by IITPL server from the Visitors' browser. ("Information").`,
            `By using the site and/or registering yourself at the site, you authorize IITPL/its parent company/group companies/associates/affiliates/partnered banks/associate partners/service providers to contact you via email or phone call and offer you their services for the product you have opted for and also to impart product knowledge, inform about promotional offers running on the site & offers of the affiliates and associated third parties, irrespective of the fact that you have registered yourself under DND or DNC or NCPR service. You also authorize IITPL to make your details available to its parent company/group companies/associates/partnered banks/affiliates/associate partners/service providers for the abovementioned purposes.`,
            `You hereby authorize and expressly give your consent to us to share your demographic and Personal Information with third parties including, but not limited to, Credit Information Companies to do an aggregate check of your credit profile and for IITPL to send you targeted communications and offers by agreeing to the Terms of Use while accessing the Website. You hereby also give your consent to IITPL to procure credit information and indicative scores from the credit information companies on your behalf.`
        ]
    },
    {
        title: 'Features of the Policy:',
        body: [
            `Information collected shall only be used to provide the Visitor with the best possible services.`,
            `Information shall not be shared with any external organisation unless the same is necessary to enable IITPL to provide you services or to enable the compilation of a transaction or the same is necessary or required pursuant to applicable norms or pursuant to the terms and conditions applicable to such Information as agreed to with IITPL. However, we will be sharing your Personal Information/ Sensitive Information with our affiliates/partnered banks/associate partners/service providers including Credit Information Companies, for the purpose of underwriting and approval of your credit card, loan or any other financial product transaction/related transaction or for doing an aggregate check of your credit profile on your behalf and sending you targeted communications and offers. IITPL may also share Information to provide you with superior services and a range of offers.`,
            `IITPL has a business relationship with these affiliates/partnered banks/associate partners/service providers and you may not opt-out of sharing your information with these partners if you have applied via a co-branded URL or directly through the Website as the case may be. However, if you desire IITPL to limit such sharing whereby you would not like to be informed of offers available, you may contact us at customercare@bajajcapital.com `,
            `From the online forms you fill out, we will also collect details of your name, including first name, E-mail address, contact details, postal code, demographic profile, your interest and financial information disclosed by you. Besides transmitting the online forms to the concerned third party service provider/product manufacturer, for whose privacy practice we are not responsible, this information will be used for getting in touch with you and providing services that you request, to obtain feedback on products and to send you promotional material from advertisers and sponsors. We will also store the history of your transactions for our record purposes. For the purpose of providing you information on services requested by you, we may share your E-mail Id, demographic profile and your interests with other service providers, unless you specifically require us not to do so.`,
            `For availing the Service such as applying for a loan, credit card, and/or any other financial product we will require you to provide/upload on the Website the details such as a your name, parentage, marital status, email address, nationality, location, mobile number, PAN, employment & income details/proofs, Form 26 AS, recent photograph, signature image, other Know Your Customer (KYC) documents like address proof, identity proof and personally identifying information about a potential co-loan applicant (if applicable) (collectively the "Registration Information").`,
            `There are number of products/services such as loans, credit cards offered by third Parties on the Website, such as lenders, banks, credit card issuers. If you choose to apply for these products or services/disclose information to these providers, then their use of your information is governed by their privacy policies. IITPL is not responsible for the privacy practices of other web sites, whose links are on our web site since we do not own, manage or control them.`,
            `By using the services on this website, you are deemed to have consented to our sending information about you to other companies or people when we require sharing your information to provide you the product or services you have requested of us.`,
            `IITPL will use the Information to improve the Visitor experience.`,
            `The Site uses cookies. Cookies are small data files that a website stores on your computer. We use persistent cookies which are permanently placed on your computer to store non-personal (Browser, ISP, OS, Clickstream information etc.) and profiling information (age, gender, income etc.). While cookies have unique identification no, personal information (name, a/c no, contact no etc.) SHALL NOT be stored on the cookies. We will use the information stored in the cookies to improve Visitor experience through throwing up relevant content where possible. We will also use the cookies to store Visitor preferences to ease Visitor navigation on the site.`,
            `We may in the future implement encryption of the cookies.`,
            `Data is stored in encrypted format while in transit and while at rest.`,
            `Access to the data is available only via multi-level authorisation and on need-to-know basis.`,
            `We maintain an audit trail of customers’ point of origin, device IP and location.`,
            `We use third-party advertising companies to serve ads on our Web site and other Web sites. In the course of serving advertisements, these companies may place or recognize a unique 'cookie' on your hard drive, and may use information (not including your name, address, email address, or telephone number) about your visits to this and other web sites in order to measure advertising effectiveness and to provide advertisements about goods and services of interest to you.`,
            `You have the ability to correct or change certain information in our records, such as your address and contact information. If you need assistance or have questions about correcting information, you can contact us via e-mail at customercare@bajajcapital.com`,
            `The information we receive from or about you is stored on systems designed to prevent the loss, misuse, unauthorized access, disclosure, alteration or destruction of that information. We may encrypt your transmission of sensitive information to us (e.g. your name, address, contact details, etc.) in the interest of heightened privacy protection and information integrity.`,
            `We do not store any information pertaining to your credit card information in our database nor do we have any knowledge of the information you enter while making payment.`,
            `IITPL also may disclose information about you as permitted or required by law.`,
            `Any changes to this Privacy Policy will be posted on the website. You are responsible for periodically checking our web site for changes to this Privacy Policy. You may opt-out of any posted change to our collection, use or disclosure of your personal information by sending an e-mail to customercare@bajajcapital.com`,
            `At IITPL, we value your relationship and will, at all times, strive to ensure your privacy.`
        ]
    }
];

export default policies;