import React from 'react';
import { Button } from 'react-bootstrap';

export default ({ search, onSearch, onCategorySelect, category, tags }) => {
    return (
        <section className="aboutbanner blog-header pt100 pb80">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 sec-heading text-center">
                        <h1><img src={require('../../../../assets/images/the-capital-blog2.png')} alt="capital_blog" width={438} height={138} className="img-fluid" /></h1>
                        <div className="input-group ">
                            <input className="form-control shadow-sm  border-0 " autoComplete="off"
                                type="text" placeholder="Search Blog" aria-label="Search"
                                id="mysearch" onChange={onSearch} value={search} />
                            <div className="input-group-addon">
                                <i className="fa fa-search" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 offset-md-2 btn-badge text-center btn-badges">
                        <Button variant="link" onClick={() => onCategorySelect('')}
                            className={`btn btn-secondary btn-badge ${(!category) && 'active'}`}> All Articles</Button>
                        {tags.length ? tags.map(tag => (<Button variant="link" onClick={() => onCategorySelect(tag.name)}
                            key={tag.name} className={`btn btn-secondary btn-badge ${(category === tag.name) && 'active'}`}>
                            {tag.name}</Button>)) : null}
                    </div>
                </div>
            </div>
        </section>
    )
}